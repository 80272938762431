import React from 'react';
// import { Button } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const BottomCtaLogin = () => {
    return (
        <div className='text-center'>
            <div className='container-fluid p-3' style={{ backgroundColor: 'black', color: 'white' }}>
                <h1 className='text-center'>
                    Start trading now
                </h1>
                <div className='d-flex justify-content-center align-items-center mt-3' >
                    <div className='col-lg-2 col-sm-12'>
                        <Nav.Link as={Link} to='/buysell' className='btn p-3 bg-warning text-dark custom-btn'>
                            <i className='fas fa-exchange-alt'></i> Trade Now
                        </Nav.Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomCtaLogin;
