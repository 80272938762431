import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdatePrice = () => {
    const [price, setPrice] = useState('');
    const [direction, setDirection] = useState('rise');
    const [status, setStatus] = useState('');
    const [currentPrice, setCurrentPrice] = useState(null);  // To store the current price

    // Fetch current price when the component mounts
    useEffect(() => {
        const fetchCurrentPrice = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/current-price');
                if (response.status === 200) {
                    setCurrentPrice(response.data.currentPrice);
                } else {
                    setCurrentPrice('Not available');
                }
            } catch (error) {
                console.error('Error fetching current price:', error);
                setCurrentPrice('Error fetching price');
            }
        };

        fetchCurrentPrice();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://antleverage.com/api/update-price', {
                amount: price,
                direction: direction, // Include the direction in the request
            });

            if (response.status === 200) {
                setStatus('Price updated successfully');
            } else {
                setStatus('Failed to update price');
            }
        } catch (error) {
            console.error('Error updating price:', error);
            setStatus('Error updating price');
        }
    };

    return (
        <div className='container card mt-3 p-3'>
            <h3>Update Price</h3>

            {/* Display current price */}
            <div className="form-group">
                <label>Current Price:</label>
                <p>{currentPrice !== null ? currentPrice : 'Loading...'}</p>
            </div>

            <form onSubmit={handleSubmit} className='form-control'>
                <div className="form-group">
                    <label htmlFor="direction">Direction:</label>
                    <select
                        id="direction"
                        className='form-control'
                        value={direction}
                        onChange={(e) => setDirection(e.target.value)}
                        required
                    >
                        <option value="rise">Rise</option>
                        <option value="fall">Fall</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>
                        Amount:
                        <input
                            type="number"
                            className='form-control'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <br />
                <button type="submit" className='btn btn-success mt-3'>Update</button>
            </form>
            {status && <p>{status}</p>}
        </div>
    );
};

export default UpdatePrice;
