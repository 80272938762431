// QuickRecharge.js
import React, { useState } from 'react';
import { Container, Card, Form, Button } from 'react-bootstrap';
import { useUser } from './UserContext';

const QuickRecharge = () => {
    const { user } = useUser();
    const [quantity, setQuantity] = useState('');
    const [paymentAddress, setPaymentAddress] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Display alert message
        window.alert('Submitted successfully!');

        // Clear the form fields
        setQuantity('');
        setPaymentAddress('');
    };
    if (!user) {
        return <p>Please log in to quick recharge.</p>;
    }
    return (
        <Container className="py-5">
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Quick Recharge</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formQuantity">
                            <Form.Label>Recharge Quantity</Form.Label>
                            <Form.Control
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                placeholder="Enter quantity"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPaymentAddress" className="mt-3">
                            <Form.Label>Payment Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={paymentAddress}
                                onChange={(e) => setPaymentAddress(e.target.value)}
                                placeholder="Enter payment address"
                                required
                            />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="mt-3">
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default QuickRecharge;
