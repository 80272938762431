// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const loginUser = async (userData) => {
        // Assuming userData contains a token or userId for authentication
        try {
            const response = await axios.get(`https://antleverage.com/api/users/${userData.id}`);
            const userWithRole = response.data;
            setUser(userWithRole);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const logoutUser = () => {
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};
