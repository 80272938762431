import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './UserContext'; // Assuming UserContext provides logged-in user data
import './ChatComponent.css'; // Import your custom CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles

const Chat = () => {
    const { user } = useUser(); // Fetching the logged-in user's info
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        // Fetch chat messages where the logged-in user is either the sender or recipient
        if (user && user.id) {
            axios.get(`https://antleverage.com/api/chat/${user.id}`)
                .then(res => setMessages(res.data))
                .catch(err => console.error(err));
        }
    }, [user]);

    const handleSendMessage = async () => {
        const formData = new FormData();
        formData.append('senderId', user.id); // Logged-in user's ID as sender
        formData.append('recipientId', 1); // Change this to the actual recipient ID
        formData.append('message', newMessage);
        if (file) formData.append('file', file);

        try {
            const res = await axios.post('https://antleverage.com/api/chat', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessages([...messages, res.data]);
            setNewMessage('');
            setFile(null);
        } catch (err) {
            console.error(err);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setFile(selectedFile);
        } else {
            alert('Only image files are allowed');
        }
    };

    return (
        <div className="container chat-container">
            <div className="chat-messages border p-3 mb-3">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`mb-2 ${msg.sender_id === user.id ? "message-sent text-right" : "message-received text-left"}`}
                    >
                        <div className="message-info">
                            <strong>{msg.sender_id === user.id ? 'You' : msg.sender_name}:</strong>
                        </div>
                        {msg.message && <p className="message-text">{msg.message}</p>}
                        {msg.file_url && <img src={`https://antleverage.com${msg.file_url}`} alt="Attachment" className="img-fluid" />}
                        <small className="text-muted">{new Date(msg.timestamp).toLocaleString()}</small>
                    </div>
                ))}
            </div>
            <div className="chat-input form-group">
                <textarea
                    className="form-control mb-2"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
                <input
                    type="file"
                    className="form-control-file mb-2"
                    onChange={handleFileChange}
                    accept="image/*"
                />
                <button
                    className="btn btn-primary"
                    onClick={handleSendMessage}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;
