import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const UpdateWalletID = () => {
    const [walletId, setWalletId] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        // Fetch the current wallet ID when the component mounts
        const fetchWalletID = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/get-wallet-id');
                setWalletId(response.data.walletid || '');
            } catch (error) {
                console.error('Error fetching wallet ID:', error);
            }
        };

        fetchWalletID();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUpdating(true);

        try {
            await axios.post('https://antleverage.com/api/update-wallet-id', { walletId });
            alert('Wallet ID updated successfully!');
        } catch (error) {
            console.error('Error updating wallet ID:', error);
        }

        setIsUpdating(false);
    };

    return (
        <Card className="mb-4">
            <Card.Body>
                <h2 className="text-center mb-4">Update Wallet ID</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formWalletID">
                        <Form.Label>Wallet ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter new wallet ID"
                            value={walletId}
                            onChange={(e) => setWalletId(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button className='mt-3' variant="primary" type="submit" disabled={isUpdating}>
                        {isUpdating ? 'Updating...' : 'Update Wallet ID'}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default UpdateWalletID;
