import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome

const NavbarComponent = () => {
    const { user, logoutUser } = useUser(); // user object is destructured from context
    const isAdmin = user && user.role === 'admin'; // Check if the user is admin
    const [unreadMessages, setUnreadMessages] = useState(0); // State to store unread messages count

    // Function to fetch unread messages count
    const fetchUnreadMessages = async () => {
        try {
            if (user && user.id) { // Ensure user object and user.id exists
                const response = await fetch(`https://antleverage.com/api/chat/unread/${user.id}`); // Fetch unread messages
                const data = await response.json(); // Parse response to JSON
                // console.log(data); // Debugging: log response
                setUnreadMessages(data.unreadMessages.unreadCount); // Set the unread message count
            }
        } catch (error) {
            console.error('Error fetching unread messages:', error); // Error handling
        }
    };

    // Fetch unread messages every 3 seconds
    useEffect(() => {
        let intervalId;

        if (user) {
            // Fetch unread messages immediately on component mount
            fetchUnreadMessages();

            // Set an interval to fetch unread messages every 3 seconds
            intervalId = setInterval(() => {
                fetchUnreadMessages();
            }, 3000); // 3000ms = 3 seconds
        }

        // Cleanup the interval when the component unmounts or when the user changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [user]); // The effect runs when the user changes

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <i className="fas fa-ant"></i> Ant
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/">
                            <i className="fas fa-home"></i> Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact">
                            <i className="fas fa-envelope"></i> Contact
                        </Nav.Link>
                        <Nav.Link as={Link} to="/tradingdata">
                            <i className="fas fa-chart-line"></i> Markets
                        </Nav.Link>
                        {!user && (
                            <Nav.Link as={Link} to="/login">
                                <i className="fas fa-sign-in-alt"></i> Login
                            </Nav.Link>
                        )}
                        {user && (
                            <>
                                <Nav.Link as={Link} to="/buysell">
                                    <i className="fas fa-exchange-alt"></i> Trade
                                </Nav.Link>
                                <Nav.Link as={Link} to="/profile">
                                    <i className="fas fa-user"></i> Profile
                                </Nav.Link>
                                <Nav.Link as={Link} to="/customer-service">
                                    <i className="fas fa-headset"></i> Customer Service
                                </Nav.Link>
                                <Nav.Link as={Link} to="/quick-recharge">
                                    <i className="fas fa-bolt"></i> Quick Recharge
                                </Nav.Link>



                                {isAdmin && (
                                    <>
                                        <Nav.Link as={Link} to="/update-wallet">
                                            <i className="fas fa-wallet"></i> Update Wallet
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/userslist">
                                            <i className="fas fa-users"></i> User List
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/roles">
                                            <i className="fas fa-user-shield"></i> Assign Roles
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/update-price">
                                            <i className="fas fa-dollar-sign"></i> Update Trade Prices
                                        </Nav.Link>
                                        {/* Notification Link */}
                                        <Nav.Link as={Link} to="/userslist">
                                            <i className="fas fa-bell"></i> Notifications
                                            {unreadMessages > 0 && (
                                                <Badge variant="danger" className="ml-1">
                                                    {unreadMessages}
                                                </Badge>
                                            )}
                                        </Nav.Link>
                                    </>
                                )}
                                <Nav.Link as={Link} to="/" onClick={logoutUser}>
                                    <i className="fas fa-sign-out-alt"></i> Logout
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
