import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { useUser } from './UserContext';

const ProfilePage = () => {
    const { user } = useUser();
    const [balance, setBalance] = useState(0.00);
    const [leverage, setLeverage] = useState(0.00);
    const [fundingBalance, setFundingBalance] = useState(0.00);
    const [frenchBalance, setFrenchBalance] = useState(0.00);
    const [tradingHistory, setTradingHistory] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [transferType, setTransferType] = useState('balanceToLeverage');
    const [paymentAddress, setPaymentAddress] = useState(''); // New state for Payment Address
    // console.log(user);
    useEffect(() => {
        if (!user) {
            return; // Optionally handle unauthenticated user
        }

        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`https://antleverage.com/api/profile/${user.id}`);
                setBalance(Number(response.data.balance) || 0.00);
                setLeverage(Number(response.data.leverage) || 0.00);
                setFundingBalance(Number(response.data.funding_balance) || 0.00);
                setFrenchBalance(Number(response.data.french_balance) || 0.00);
                setTradingHistory(response.data.tradingHistory);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [user]);

    const handleTransfer = async () => {
        let endpoint;
        switch (transferType) {
            // Forward transfers
            case 'balanceToLeverage':
                endpoint = '/api/transfer/balance-to-leverage';
                break;
            case 'leverageToFunding':
                endpoint = '/api/transfer/leverage-to-funding';
                break;
            case 'fundingToFrench':
                endpoint = '/api/transfer/funding-to-french';
                break;

            // Reverse transfers
            case 'frenchToFunding':
                endpoint = '/api/transfer/french-to-funding';
                break;
            case 'fundingToLeverage':
                endpoint = '/api/transfer/funding-to-leverage';
                break;
            case 'leverageToBalance':
                endpoint = '/api/transfer/leverage-to-balance';
                break;

            default:
                return;
        }

        try {
            const response = await axios.post(`https://antleverage.com${endpoint}`, {
                userId: user.id,
                amount: transferAmount,
                ...(transferType === 'fundingToFrench' && { paymentAddress }), // Include paymentAddress if transferring to French balance
            });

            // Update balances after successful transfer
            setBalance(Number(response.data.balance) || balance);
            setLeverage(Number(response.data.leverage) || leverage);
            setFundingBalance(Number(response.data.funding_balance) || fundingBalance);
            setFrenchBalance(Number(response.data.french_balance) || frenchBalance);
            setShowModal(false);  // Close the modal after transfer

            // Alert success after the transfer
            alert('Transfer successful!');
        } catch (error) {
            alert('Error please contact customer care');
            console.error('Transfer error:', error);
        }
    };

    if (!user) {
        return <p>Please log in to view your profile.</p>;
    }

    return (
        <Container fluid className="py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Body>
                            <h1 className="text-center mb-4">Profile</h1>
                            <h2 className="text-center mb-4">Current Balance: {balance.toFixed(2)} USDT</h2>
                            <h2 className="text-center mb-4">Leverage: {leverage.toFixed(2)} USDT</h2>
                            <h2 className="text-center mb-4">Funding Balance: {fundingBalance.toFixed(2)} USDT</h2>
                            <Button onClick={() => setShowModal(true)}>Transfer Funds</Button>
                            <h3 className="text-center mb-4">Trading History</h3>
                            <ListGroup variant="flush">
                                {tradingHistory.length > 0 ? (
                                    tradingHistory.map((trade, index) => {

                                        
                                        const previousTrade = index > 0 ? tradingHistory[index - 1] : null;
                                        let profitOrLoss = null;

                                        

                                        

                                        if (previousTrade) {
                                            profitOrLoss = Number(trade.amount) - Number(previousTrade.amount);
                                        }

                                        return (
                                            <ListGroup.Item
                                                key={index}
                                                className={trade.trade_type === 'buy' ? 'bg-success text-white' : 'bg-danger text-white'}
                                            >
                                                <Row>
                                                    <Col><strong>{trade.trade_type}</strong></Col>
                                                    <Col className="text-end">{Number(trade.amount).toFixed(2)} USDT</Col>
                                                    <Col className="text-end">{new Date(trade.trade_date).toLocaleString()}</Col>
                                                    <Col className="text-end">
                                                        {profitOrLoss !== null ? (
                                                            <span className={profitOrLoss >= 0 ? 'text-success' : 'text-danger'}>
                                                                {profitOrLoss >= 0 ? '+' : ''}{profitOrLoss.toFixed(2)} USDT
                                                            </span>
                                                        ) : (
                                                            <span>-</span>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    })
                                ) : (
                                    <p className="text-center">No trading history available.</p>
                                )}
                            </ListGroup>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Transfer Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Funds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="transferType">
                            <Form.Label>Transfer Type</Form.Label>
                            <Form.Control as="select" value={transferType} onChange={e => setTransferType(e.target.value)}>
                                {/* Forward transfer options */}
                                <option value="balanceToLeverage">Balance to Leverage</option>
                                <option value="leverageToFunding">Leverage to Funding Balance</option>
                                <option value="fundingToFrench">Funding Balance to French Balance</option>

                                {/* Reverse transfer options */}
                                <option value="fundingToLeverage">Funding Balance to Leverage</option>
                                <option value="leverageToBalance">Leverage to Balance</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="transferAmount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount"
                                value={transferAmount}
                                onChange={e => setTransferAmount(Number(e.target.value))}
                            />
                        </Form.Group>

                        {/* Payment Address field for fundingToFrench */}
                        {transferType === 'fundingToFrench' && (
                            <Form.Group controlId="paymentAddress">
                                <Form.Label>Payment Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter payment address"
                                    value={paymentAddress}
                                    onChange={e => setPaymentAddress(e.target.value)}
                                />
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleTransfer}>
                        Transfer
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ProfilePage;
