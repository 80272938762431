import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';
import { Table, Container, Button, Carousel } from 'react-bootstrap';
import BottomCtaLogin from './BottomCtaLogin';
import './TradingData.css'; // Import your custom CSS file
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// const socket = io('https://antleverage.com');

const TradingData = () => {
    const [data, setData] = useState([]);
    console.log(data);

    // Function to fetch data from the new API endpoint
    const fetchData = async () => {
        try {
            const response = await fetch('https://antleverage.com/api/trading-data'); // Assuming the backend is running on the same server
            const newData = await response.json();
            if (Array.isArray(newData) && newData.length > 0) {
                setData(newData);
            }
        } catch (error) {
            console.error('Error fetching trading data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data immediately when the component mounts

        const intervalId = setInterval(fetchData, 30000); // Fetch every 30 seconds
        return () => clearInterval(intervalId); // Clean up the interval on unmount
    }, []);

    return (
        <div>
            {/* Carousel Slider */}
            <Carousel className="hero-carousel text-white text-center ">
                <Carousel.Item style={{ backgroundImage: 'url(/banner.png)', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px' }}>
                    <Container className='mt-3'>
                        <h1>Welcome to Ant</h1>
                        <h2>Your go-to platform for live trading data and insights.</h2>
                        <div className='d-flex justify-content-center align-items-center' >
                            <div className='col-lg-3 col-sm-12'>
                                <Nav.Link as={Link} to='/buysell' className='btn p-3 bg-warning text-dark custom-btn'>
                                    <i className='fas fa-exchange-alt'></i> Trade Now
                                </Nav.Link>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: 'url(/banner2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px' }}>
                    <Container className='mt-3'>
                        <h1>Welcome to Ant</h1>
                        <h2>The Worlds leading digital trading platform.</h2>
                        <div className='d-flex justify-content-center align-items-center' >
                            <div className='col-lg-3 col-sm-12'>
                                <Nav.Link as={Link} to='/buysell' className='btn p-3 bg-warning text-dark custom-btn'>
                                    <i className='fas fa-exchange-alt'></i> Trade Now
                                </Nav.Link>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: 'url(/banner3.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', height: '300px' }}>
                    <Container className='mt-3'>
                        <h1>Welcome to Ant</h1>
                        <h2>The Worlds leading digital trading platform.</h2>
                        <div className='d-flex justify-content-center align-items-center' >
                            <div className='col-lg-3 col-sm-12'>
                                <Nav.Link as={Link} to='/buysell' className='btn p-3 bg-warning text-dark custom-btn'>
                                    <i className='fas fa-exchange-alt'></i> Trade Now
                                </Nav.Link>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>

            </Carousel>

            <Container fluid className="mt-5">
                <h2 className="text-center mb-4">Current Stocks</h2>
                <Carousel className="multi-item-carousel">
                    {data.length > 0 && data.map((stock, index) => (
                        <Carousel.Item key={index}>
                            <div className="d-flex justify-content-around flex-nowrap">
                                {data.slice(index * 3, (index + 1) * 3).map((stockItem, i) => (
                                    <div key={i} className="card carousel-item-content text-center">
                                        <p>{stockItem.symbol}/<span>USDT</span></p>
                                        <h2 className={stockItem.change.startsWith('+') ? 'text-success' : 'text-danger'}>
                                            {stockItem.price}
                                        </h2>
                                        <p className={stockItem.change.startsWith('+') ? 'text-success' : 'text-danger'}>
                                            Change: {stockItem.change}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>

                <div className='row m-4 d-flex justify-content-around'>
                    <div className='col'>
                        <Nav.Link as={Link} to='/quick-recharge' className='btn p-3 bg-light custom-btn'>
                            <i className='fas fa-bolt'></i> Quick Recharge
                        </Nav.Link>
                    </div>
                    <div className='col'>
                        <Nav.Link as={Link} to='/customer-service' className='btn p-3 bg-light custom-btn'>
                            <i className='fas fa-headset'></i> Customer Service
                        </Nav.Link>
                    </div>
                    <div className='col'>
                        <Nav.Link as={Link} to='/profile' className='btn p-3 bg-light custom-btn'>
                            <i className='fas fa-user'></i> Profile
                        </Nav.Link>
                    </div>
                    <div className='col'>
                        <Nav.Link as={Link} to='/buysell' className='btn p-3 bg-light custom-btn'>
                            <i className='fas fa-exchange-alt'></i> Trade Now
                        </Nav.Link>
                    </div>
                </div>

                <h2 className="text-center mt-5 mb-4">Live Trading Data</h2>
                <Table striped bordered hover className="styled-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Symbol</th>
                            <th>Price</th>
                            <th>Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            const backgroundColor = item.change.startsWith('+') ? '#d4edda' : '#f8d7da'; // Bootstrap success and danger colors
                            const textColor = item.change.startsWith('+') ? '#155724' : '#721c24'; // Bootstrap success and danger text colors

                            return (
                                <tr key={index} className="table-row">
                                    <td>{item.name}</td>
                                    <td>
                                        {item.symbol}/
                                        <span style={{ color: 'grey', fontSize: '12px' }}>USDT</span>
                                    </td>
                                    <td className={item.change.startsWith('+') ? 'text-success' : 'text-danger'}>
                                        {item.price}
                                    </td>
                                    <td
                                        style={{
                                            backgroundColor,
                                            color: textColor,
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {item.change}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>

            <div>
                <BottomCtaLogin />
            </div>
        </div>
    );
};

export default TradingData;
